import styled from "styled-components"

export const Container = styled.div`
  margin: 15px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &.working {
    color: #aaa;
  }

  &.success {
    color: #155724;
  }

  &.error {
    color: #721c24;
  }

  &.notice {
    color: #856404;
  }

  & > span {
    padding-top: 15px;
    font-size: 1.25rem;
  }
`
