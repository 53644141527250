import styled from "styled-components"
import {
  Main as BaseMain,
  MainContainer as BaseMainContainer,
  ButtonContainer as BaseButtonContainer,
} from "../../../elements/Layout/styled"

export const Main = styled(BaseMain)``

export const MainContainer = styled(BaseMainContainer)``

export const Grid = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 25px;
  align-items: center;

  @media screen and (max-width: 1023px) {
    grid-template-columns: 1fr;
  }

  & > form {
    border: 1px solid #ddd;
    padding: 15px;

    & > div.fields {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      @media screen and (max-width: 767px) {
        flex-direction: column;
        margin-bottom: 0;
      }

      & > div {
        @media screen and (max-width: 767px) {
          margin-bottom: 15px;
        }
      }

      & > div.empty {
        width: 100%;

        @media screen and (max-width: 767px) {
          display: none;
        }
      }

      & > div:first-child {
        margin-right: 7.5px;

        @media screen and (max-width: 767px) {
          margin-right: 0;
        }
      }

      & > div:last-child {
        margin-left: 7.5px;

        @media screen and (max-width: 767px) {
          margin-left: 0;
        }
      }
    }

    & > div.one {
      margin-bottom: 15px;
    }
  }

  & > div.logos {
    margin: 25px auto;
    width: 60%;
    display: flex;
    align-items: center;
    flex-direction: column;

    @media screen and (max-width: 1023px) {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
    }

    & > img:nth-child(2) {
      padding-top: 35px;
      padding-bottom: 35px;

      @media screen and (max-width: 1023px) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    & > img {
      @media screen and (max-width: 1023px) {
        width: 25%;
      }
    }
  }
`

export const ButtonContainer = styled(BaseButtonContainer)`
  padding-top: 30px;
  padding-bottom: 5px;
`
