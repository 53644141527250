import React from "react"
import { Container, Elements } from "./styled.js"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons"

const Radios = ({ req = true, name, hdg, update, data }) => {
  return (
    <Container>
      <label>
        {`${hdg}`} {req ? <span className="error">*</span> : null}
      </label>
      <Elements>
        {data.map((item, index) => {
          return (
            <label key={index} onClick={e => update(name, item.value)}>
              <FontAwesomeIcon icon={item.checked ? faCheckCircle : faCircle} />
              <span>{item.label}</span>
            </label>
          )
        })}
      </Elements>
    </Container>
  )
}

export default Radios
