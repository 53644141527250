import React, { useState } from "react"
import { API_URL } from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import Input from "../components/form/Input"
import Textarea from "../components/form/Textarea"
import Radios from "../components/form/Radios"
import Msg from "../components/form/Msg"
import Submit from "../components/form/Submit"
import Alert from "../components/Alert"
import {
  Main,
  MainContainer,
  Grid,
  ButtonContainer,
} from "../components/content/Contact/styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const Contact = () => {
  const [errors, setErrors] = useState([])
  const [names, setNames] = useState({})
  const [msg, setMsg] = useState({})
  const [alert, setAlert] = useState({})

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setErrors([])
    setMsg({
      type: "working",
      text: "",
    })

    const url = new URL(`${API_URL}/contact`)
    let data = new FormData()
    data.append("fname", names.fname || "")
    data.append("lname", names.lname || "")
    data.append("email", names.email || "")
    data.append("phone", names.phone || "")
    data.append("city", names.city || "")
    data.append("comments", names.comments || "")
    data.append("preferred", names.preferred || "")

    try {
      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        setMsg({})
        setAlert({
          type: "success",
          text: json.text,
        })
      } else {
        setErrors(json.fields)
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <Layout>
      <Helmet
        name="Contact Us"
        description="Have a question for Mechanical Air Conditioning? Fill out our contact form to send us a message."
        slug="contact"
      />
      <Header />
      <PageHdr title="Contact Us" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <h2>Send Us a Message</h2>
              <p>
                Have a question about a system, filter or other HVAC-related
                issue? Fill out the form below and a representative will get in
                touch with you shortly.
              </p>

              <Grid>
                {alert.type ? (
                  <Alert data={alert} />
                ) : (
                  <form method="post" onSubmit={e => handleSubmit(e)}>
                    <div className="fields">
                      <Input
                        type="text"
                        label="First Name"
                        req={true}
                        name="fname"
                        value={names.fname}
                        update={handleUpdate}
                        errors={errors}
                      />

                      <Input
                        type="text"
                        label="Last Name"
                        req={true}
                        name="lname"
                        value={names.lname}
                        update={handleUpdate}
                        errors={errors}
                      />
                    </div>

                    <div className="fields">
                      <Input
                        type="text"
                        label="Email"
                        req={true}
                        name="email"
                        value={names.email}
                        update={handleUpdate}
                        errors={errors}
                      />

                      <Input
                        type="text"
                        label="Phone"
                        req={true}
                        name="phone"
                        value={names.phone}
                        update={handleUpdate}
                        errors={errors}
                      />
                    </div>

                    <div className="fields">
                      <Input
                        type="text"
                        label="City"
                        req={true}
                        name="city"
                        value={names.city}
                        update={handleUpdate}
                        errors={errors}
                      />

                      <div className="empty" />
                    </div>

                    <div className="one">
                      <Textarea
                        label="Question or Comment"
                        req={true}
                        name="comments"
                        value={names.comments}
                        update={handleUpdate}
                        errors={errors}
                      />
                    </div>

                    <Radios
                      name="preferred"
                      hdg="Preferred method of contact"
                      update={handleUpdate}
                      data={[
                        {
                          value: "email",
                          label: "Email",
                        },
                        {
                          value: "phone",
                          label: "Phone Call",
                        },
                        {
                          value: "text",
                          label:
                            "Text (phone entered above is a mobile number)",
                        },
                      ].map(item => {
                        return {
                          value: item.value,
                          label: item.label,
                          checked:
                            names.preferred === item.value ? true : false,
                        }
                      })}
                    />

                    {msg.type && <Msg data={msg} />}

                    <ButtonContainer
                      className={msg.type === "working" ? "hidden" : ""}
                    >
                      <Submit
                        name="Submit Message"
                        icon={faChevronCircleRight}
                      />
                    </ButtonContainer>
                  </form>
                )}

                <div className="logos">
                  <img
                    src="https://tjpc.com/img/franklin-logo.svg"
                    alt="Franklin AC Logo"
                  />
                  <img
                    src="https://tjpc.com/img/goodman-logo.png"
                    alt="Goodman AC Logo"
                  />
                  <img
                    src="https://tjpc.com/img/carrier-logo.png"
                    alt="Carrier AC Logo"
                  />
                </div>
              </Grid>
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Contact
