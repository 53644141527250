import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & label {
    padding-bottom: 2.5px;
    font-size: 0.9rem;
    cursor: pointer;

    & > span.error {
      color: #721c24;
    }
  }

  & textarea {
    width: 100%;
    height: 70px;
    border: 1px solid #cecece;
    border-radius: 4px;
    vertical-align: middle;
    padding: 5px 7.5px;
    font-size: 16px;
    font-weight: 600;
    outline: none;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    -webkit-appearance: none;
  }

  & textarea.error-border {
    border-color: #721c24;
  }

  & textarea:focus {
    border-color: #2d77b2;
  }
`
