import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & label {
    padding-bottom: 7.5px;
    cursor: pointer;

    & > span.error {
      color: #721c24;
    }
  }
`

export const Elements = styled.div`
  margin-left: 15px;
  margin-top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & > label {
    display: block;
    cursor: pointer;
    margin-left: 0;
    margin-bottom: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    font-size: 0.95rem;
  }

  & > label:hover span,
  & > label:hover svg {
    color: #2d77b2;
  }

  & > label > span {
    padding-left: 7.5px;
  }
`
